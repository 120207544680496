nav {
    /* full screen width */
    width: 100vw; 
    height: 5rem;
    display: grid;
    place-items: center;
    background: var(--color-primary);
    /* fixed on a position of the screen */
    position: fixed; 
    top: 0;
    left: 0;
    z-index: 9;
    /* The stack level of the box in the current stacking context */
    z-index: 9;
    box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue), 68%, 42%, 20%);
    transition: var(--transition);
}

/* nav img {
    display:none;
} */

.nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo {
    width: 2.5rem;
    height: 2.5rem;
    /* make it a circle */
    border-radius: 50%;
    /* do not display other part of the image outside the limit*/
    overflow: hidden;
}

.nav__menu {
    /* like a block element with respect to the parent element. takes up the full width of its container. */
    display: flex;
    gap: 3.5rem;
}

.nav__menu a {
    color: white;
}

#theme__icon {
    background: transparent;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

/* HIDE NAV MENU ON TABLETS & PHONES */
@media screen and (max-width: 1024px) {
    .nav__menu {
        display: none;
    }
}

/* from bootstrap/dist/css/bootstrap.min.css for dropdown */
.dropdown {
    position:relative
}
.btn.btn-primary.dropdown-toggle {
    white-space:nowrap
    /* .btn-primary{--bs-btn-color:#fff;--bs-btn-bg:#0d6efd;--bs-btn-border-color:#0d6efd;--bs-btn-hover-color:#fff;--bs-btn-hover-bg:#0b5ed7;--bs-btn-hover-border-color:#0a58ca;--bs-btn-focus-shadow-rgb:49,132,253;--bs-btn-active-color:#fff;--bs-btn-active-bg:#0a58ca;--bs-btn-active-border-color:#0a53be;--bs-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);--bs-btn-disabled-color:#fff;--bs-btn-disabled-bg:#0d6efd;--bs-btn-disabled-border-color:#0d6efd}.btn-secondary{--bs-btn-color:#fff;--bs-btn-bg:#6c757d;--bs-btn-border-color:#6c757d;--bs-btn-hover-color:#fff;--bs-btn-hover-bg:#5c636a;--bs-btn-hover-border-color:#565e64;--bs-btn-focus-shadow-rgb:130,138,145;--bs-btn-active-color:#fff;--bs-btn-active-bg:#565e64;--bs-btn-active-border-color:#51585e;--bs-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);--bs-btn-disabled-color:#fff;--bs-btn-disabled-bg:#6c757d;--bs-btn-disabled-border-color:#6c757d}.btn-success{--bs-btn-color:#fff;--bs-btn-bg:#198754;--bs-btn-border-color:#198754;--bs-btn-hover-color:#fff;--bs-btn-hover-bg:#157347;--bs-btn-hover-border-color:#146c43;--bs-btn-focus-shadow-rgb:60,153,110;--bs-btn-active-color:#fff;--bs-btn-active-bg:#146c43;--bs-btn-active-border-color:#13653f;--bs-btn-active-shadow:inset 0 3px 5px rgba(0, 0, 0, 0.125);--bs-btn-disabled-color:#fff;--bs-btn-disabled-bg:#198754;--bs-btn-disabled-border-color:#198754} */
}

.dropdown-menu {
    --bs-dropdown-zindex:1000;
    --bs-dropdown-min-width:10rem;
    --bs-dropdown-padding-x:0;
    --bs-dropdown-padding-y:0.5rem;
    --bs-dropdown-spacer:0.125rem;
    --bs-dropdown-font-size:1rem;
    --bs-dropdown-color:var(--bs-body-color);
    --bs-dropdown-bg:var(--bs-body-bg);
    --bs-dropdown-border-color:var(--bs-border-color-translucent);
    --bs-dropdown-border-radius:var(--bs-border-radius);
    --bs-dropdown-border-width:var(--bs-border-width);
    --bs-dropdown-inner-border-radius:calc(var(--bs-border-radius) - var(--bs-border-width));
    --bs-dropdown-divider-bg:var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y:0.5rem;
    --bs-dropdown-box-shadow:var(--bs-box-shadow);
    --bs-dropdown-link-color:var(--bs-body-color);
    --bs-dropdown-link-hover-color:var(--bs-body-color);
    --bs-dropdown-link-hover-bg:var(--bs-tertiary-bg);
    --bs-dropdown-link-active-color:#fff;
    --bs-dropdown-link-active-bg:#0d6efd;
    --bs-dropdown-link-disabled-color:var(--bs-tertiary-color);
    --bs-dropdown-item-padding-x:1rem;
    --bs-dropdown-item-padding-y:0.25rem;
    --bs-dropdown-header-color:#6c757d;
    --bs-dropdown-header-padding-x:1rem;
    --bs-dropdown-header-padding-y:0.5rem;
    position:absolute;
    z-index:var(--bs-dropdown-zindex);
    display:none;
    min-width:var(--bs-dropdown-min-width);
    padding:var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin:0;
    font-size:var(--bs-dropdown-font-size);
    color:var(--bs-dropdown-color);
    text-align:left;
    list-style:none;
    background-color:var(--bs-dropdown-bg);
    background-clip:padding-box;
    border:var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius:var(--bs-dropdown-border-radius)
}

.dropdown-item-text {
    display:block;
    padding:var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color:var(--bs-dropdown-link-color)
} 

.dropdown-item {
    display:block;
    width:100%;
    padding:var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear:both;
    font-weight:400;
    color:var(--bs-dropdown-link-color);
    text-align:inherit;
    text-decoration:none;
    white-space:nowrap;
    background-color:transparent;
    border:0;
    border-radius:var(--bs-dropdown-item-border-radius,0)
}