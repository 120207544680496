.services__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 4rem;
}

.card.service {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.service__icon {
    background: var(--color-primary);
    color: var(--color-white);
    padding: 0.6rem;
    border-radius: var(--border-radius-3);
    font-size: 1.5rem;
    transition: var(--transition);
}

.service.card.service:hover .service__icon {
    background-color: var(--color-light);
    color: var(--color-primary);
    transform: translateX(-0.5rem);
}

.service h4 {
    margin-bottom: 0.7rem;
}


/* MEDIA QUERIES (tablets) */
@media screen and (max-width:1024px) {
    .services__container {
        gap: 1.5rem;
    }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width:600px) {
    .services__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }

    .card.service {
        gap: 1.2rem;
    }
}
